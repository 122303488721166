'use client'

import Toast from "@/components/Toast"
import Image from "next/image"
import CenterLayout from "@/layout/CenterLayout"
import { images } from "@/utils/images"
import { Button, Input, PasswordInput } from "@mantine/core"
import themeVariables from "@/config/theme/themeVariable"
import { useRef } from "react"
import { API_ENDPOINT } from "@/config/endpoint/endpoint"

export default function LoginPage() {
  const idRef = useRef<HTMLInputElement>(null)
  const passwordRef = useRef<HTMLInputElement>(null)

  const handleLogin = async () => {
    if (!idRef.current?.value || !passwordRef.current?.value) {
      Toast.error('아이디와 비밀번호를 입력해주세요')
      return
    }
    await API_ENDPOINT.account.login(idRef.current?.value, passwordRef.current?.value)
    .then(data => {
      if (data.result) {
        location.href = '/'
      }
    })
    .catch(err => {
      Toast.error('아이디와 비밀번호를 확인해주세요')
    })
  }


  return (
    <>
      <CenterLayout>
        <div className="flex flex-col w-full m-auto items-center gap-0 pt-32">
          <Image width={80} height={80} src={images.logo} className="mb-4" alt='company-logo'/>
          <div className="text-[28px] font-bold tracking-tight">어드민 계정으로</div>
          <div className="text-[28px] font-bold tracking-tight">로그인해주세요</div>
          <div className="flex flex-col gap-1.5 w-[300px] mt-10">
            <Input
              ref={idRef}
              leftSectionWidth={80}
              leftSection={<div className="text-gray-500 text-[13px] font-semibold w-full pl-4">아이디</div>}
              classNames={{
                input: `${themeVariables.input.filled.input} !h-11`
              }}
            />
            <PasswordInput
              ref={passwordRef}
              leftSectionWidth={80}
              leftSection={<div className="text-gray-500 text-[13px] font-semibold w-full pl-4">비밀번호</div>}
              classNames={{
                input: `${themeVariables.input.filled.input} !h-11`
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleLogin();
                }
              }}
            />
            <Button
              className="mt-2"
              classNames={themeVariables.button.dark}
              onClick={handleLogin}
            >
              로그인
            </Button>
          </div>
        </div>
      </CenterLayout>
    </>
  )
}